.infoBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.8rem 0;
    max-width: calc(80vw - 4rem);
    span {
        font-size: 1.25em;
        margin: auto 0;
        user-select: none;
    }
    p, a {
        margin: 0;
        color: inherit;
        transition: color 0.3s;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    a:hover {
        color: #F80;
    }
}
