@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,1,0");

@font-face {
  font-family: "icomoon";
  src: url("App/icons/fonts/icomoon.eot?3yb6qh");
  src:
    url("App/icons/fonts/icomoon.eot?3yb6qh#iefix") format("embedded-opentype"),
    url("App/icons/fonts/icomoon.ttf?3yb6qh") format("truetype"),
    url("App/icons/fonts/icomoon.woff?3yb6qh") format("woff"),
    url("App/icons/fonts/icomoon.svg?3yb6qh#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@media (max-aspect-ratio: 1/1) {
  :root {
    font-size: max(2.5vw, 1rem);
  }
}
@media (min-aspect-ratio: 1/1) {
  :root {
    font-size: min(1.2vw, 1rem);
  }
}

::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.1rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #8888;
  border-radius: 0.1rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #AAA;
  border-radius: 0.1rem;
}

body {
  margin: 0;
  font-family: "Noto Sans TC", sans-serif;
}

.ms-o {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.im {
  font-family: "icomoon";
}
